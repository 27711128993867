import { useMemo } from "react";
import useAPI from "../../hooks/useAPI";
import { shortDateFormat, moodLabel, moodMap, formatDate } from "../../utils";
import Loader from "../Loader";
import useCalendar from "../Calendar/useCalendar";
import CalendarNav from "../Calendar/CalendarNav";
import DisplayNoDataMessage from "../Template/DisplayNoDataMessage";
import Delete from '../FormTemplate/Delete';
import { goToLogs, PATH_MOOD } from '../../endpointConfig';

const MoodCalendar = () => {
	const { value: mood, loading } = useAPI("motus");
	const dates = mood.map((x) => new Date(x.date));
	const calendar = useCalendar(dates);
	const date = calendar.date;

	const filterMoods = useMemo(() => {
		const yymm = formatDate(date).substr(0, 7);

		return mood.filter((x) => x.date.substr(0, 7) === yymm);
	}, [date, mood]);

	const sortedMoods = filterMoods.sort((a, b) => new Date(b.date) - new Date(a.date));

	return (
		<section className="section section--mood">
			<h1 className="section__title">Året i emoticon</h1>
			<div className="calendar">
				<header className="calendar__nav">
					<CalendarNav calendar={calendar} />
				</header>
				{loading ? (
					<Loader />
				) : filterMoods.length > 0 ? (
					<ul className="datalist moodlist">
						{sortedMoods.map(({ id, date, mood }) => (
							<li
								key={id}
								className="datalist__item moodlist__item"
							>
								<h2 className="mood__date">
									{shortDateFormat(date)}
								</h2>

								<div className="mood__content">
									<span className="mood__label">
										{moodLabel(mood)}
									</span>
									<div className="mood__icon">
										{moodMap(mood)}
									</div>
								</div>

								<Delete
									id={id}
									label={`Humör för ${shortDateFormat(date)}`}
									dbEndpoint="motus"
									pathAfterDeletion={`${goToLogs}/${PATH_MOOD}`}
								/>
							</li>
						))}
					</ul>
				) : (
					<DisplayNoDataMessage classes="calendar-presentation__msg" />
				)}
			</div>
		</section>
	);
};

export default MoodCalendar;
