import { formatDate } from "../../utils";
import Field from "../FormTemplate/Field";
import RadioOption from "../FormTemplate/Radio";
import FormCreate from "../FormTemplate/FormCreate";
import { Button } from "../Template/Button";

const table = "survey/gad7";

const Answers = ({ number }) => (
	<>
		<RadioOption
			number={number}
			cat="q"
			option="none"
			label="Inte alls"
			value="0"
		/>
		<RadioOption
			number={number}
			cat="q"
			option="slightly"
			label="Flera dagar"
			value="1"
		/>
		<RadioOption
			number={number}
			cat="q"
			option="moderate"
			label="Mer än hälften av dagarna"
			value="2"
		/>
		<RadioOption
			number={number}
			cat="q"
			option="much"
			label="Nästan varje dag"
			value="3"
		/>
	</>
);

const Section = ({ q, num }) => (
	<section className="field field--survey">
		<h3>{q}</h3>
		<Answers number={num} />
	</section>
);

const GAD7Fields = ({
	gad7 = {
		date: formatDate(new Date()),
	},
	title = "GAD-7",
}) => (
	<fieldset className="form form--survey">
		<legend>{title}</legend>
		<div className="form__layout">
			<Field
				id="suvey-date"
				name="date"
				type="date"
				label="Datum"
				value={gad7.date}
			/>

			<p className="form__instructions">
				Fyll i enkäten utifrån hur det har varit sedan föregående gång.
				<br />
				Under de senaste 2 veckorna, hur ofta har du besvärats av något av följande problem.
			</p>

			<Section
				q="Känt dig nervös, ängslig eller väldigt stressad"
				num="1"
			/>
			<Section
				q="Inte kunnat sluta oroa dig eller kontrollera din oro"
				num="2"
			/>
			<Section q="Oroat dig för mycket för olika saker" num="3" />
			<Section q="Haft svårt att slappna av" num="4" />
			<Section
				q="Varit så rastlös att du har haft svårt att sitta still"
				num="5"
			/>
			<Section q="Blivit lätt irriterad eller retlig" num="6" />
			<Section
				q="Känt dig rädd för att något hemskt skulle hända"
				num="7"
			/>

			<section className="field field--survey">
				<h3>Om något av detta förekommit, hur påverkade det din arbetsförmåga, hemsysslor och relationer?</h3>
				<RadioOption
					number="8"
					cat="q"
					option="none"
					label="Inte alls"
					value="0"
				/>
				<RadioOption
					number="8"
					cat="q"
					option="slightly"
					label="Lite"
					value="1"
				/>
				<RadioOption
					number="8"
					cat="q"
					option="moderate"
					label="Mycket"
					value="2"
				/>
				<RadioOption
					number="8"
					cat="q"
					option="extreme"
					label="Påtagligt störande"
					value="3"
				/>
			</section>
		</div>
		<div className="btn-container">
			<Button label="Spara" modifier="default" />
		</div>
	</fieldset>
);

export const AddGAD7 = () => {
	return (
		<FormCreate endpoint={table} goTo="/surveys">
			<GAD7Fields />
		</FormCreate>
	);
};
