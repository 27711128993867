import { useRef, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useUpdateEffect } from "../../hooks/useUpdateEffect";
import TabItem from "./TabItem";

const Tabs = ({ items }) => {
	const [box, setBox] = useState();
	const selectedTabRef = useRef();
	const transRef = useRef(false);
	const currentPath = useLocation().pathname.toLowerCase();

	useEffect(() => {
		if (selectedTabRef.current != null) {
			setBox({
				left: selectedTabRef.current.offsetLeft,
				width: selectedTabRef.current.offsetWidth,
			});
		}

		if (selectedTabRef && selectedTabRef.current) {
			selectedTabRef.current.scrollIntoView();
		}
	}, [currentPath]);

	useUpdateEffect(() => {
		if (selectedTabRef.current != null) {
			transRef.current = true;
		}
	}, [currentPath]);

	const setSelectedRef = (to) => {
		return currentPath === to ? selectedTabRef : null;
	};

	const style = box ? { "--w": box.width, "--l": box.left } : null;

	return (
		<nav className="tabs">
			<ul
				style={style}
				className={`tab-list ${transRef.current ? "transition" : ""}`}
			>
				{items.map((item) => (
					<TabItem
						key={item.label}
						to={item.url}
						label={item.label}
						ref={setSelectedRef(item.url)}
					/>
				))}
			</ul>
		</nav>
	);
};

export default Tabs;
