import { useState, useMemo, Fragment, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { formatDate, swedishDateFormat, moodMap, moodLabel, splitSpiritEvents } from "../../utils";
import useAPI from "../../hooks/useAPI";
import useOutsideClick from "../../hooks/useOutsideClick";
import Calendar from "../Calendar";
import CalendarCell from "../Calendar/CalendarCell";
import CalendarHeader from "../Calendar/CalendarHeader";
import DisplayNoDataMessage from "../Template/DisplayNoDataMessage";
import { CalendarLoader } from "../Loader";

const All = () => {
	const [selectedDate, setSelectedDate] = useState(new Date());
	const [openBubble, setOpenBubble] = useState(false);
	const bubbleRef = useRef(null);
	const { pathname } = useLocation();
	const classes = `calendar-presentation ${openBubble ? "is-open" : ""}`.trim();

	const { value: meds, loadingMeds } = useAPI("medicamento-logg");
	const { value: mood, loadingMood } = useAPI("motus");
	const { value: spirit, loadingSpirit } = useAPI("positivismum");
	const { value: weight, loadingWeight } = useAPI("gravitas");

	const loading = loadingMeds || loadingMood || loadingSpirit || loadingWeight;

	const data = Object.groupBy([
		...meds.map((x) => ({ ...x, type: "meds" })),
		...spirit.map((x) => ({ ...x, type: "spirit" })),
		...mood.map((x) => ({ ...x, type: "mood" })),
		...weight.map((x) => ({ ...x, type: "weight" })),
	], (x) => {
		return formatDate(new Date(x.date));
	});

	const dates = Object.keys(data).map((x) => new Date(x));

	useEffect(() => {
		setOpenBubble(false);
	}, [pathname]);

	useOutsideClick(bubbleRef, () => {
		if (openBubble) setOpenBubble(false);
	});

	const selectedData = useMemo(() => {
		const formatted = formatDate(selectedDate);
		const items = data[formatted] || [];
		const itemsByType = Object.groupBy(items, (x) => x.type);

		return {
			items,
			date: formatted,
			...itemsByType,
		};
	}, [selectedDate, data]);

	const renderDate = (date, { month }) => {
		const key = formatDate(date);
		const items = [...new Set((data[key] || []).map((x) => x.type))];

		return (
			<CalendarCell
				date={date}
				month={month}
				selectedDate={selectedDate}
				onClick={() => {
					setSelectedDate(date);
					requestAnimationFrame(() => {
						setOpenBubble(true);
					});
				}}
				active={items.length > 0}
				className="calendar__cell--all"
			>
				<div className="calendar-cell__wrapper">
					{items.map((type) => (
						<span key={type} data-cell-type={type} className="calendar-cell--all-item"></span>
					))}
				</div>
			</CalendarCell>
		);
	};

	const totalPositiveItems = selectedData?.spirit?.reduce((total, { event }) => {
		return total + (event ? splitSpiritEvents(event).length : 0);
	}, 0);

	const StatusItem = ({ title, data }) => {
		return (
			<div className={`calendar-collection__status__item ${data ? "is-checked" : ""}`}>
				<dt>{title}</dt>
				<dd>
					<svg
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg"
					>
						<circle cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="2" fill="none" />
						<path
							d="M8 12.5L11 15.5L16 9.5"
							stroke="currentColor"
							strokeWidth="2"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				</dd>
			</div>
		);
	};

	return (
		<section className="section section--calendar calendar-collection" aria-labelledby="all-calendar">
			<h1 id="all-calendar" className="section__title">All</h1>

			<dl className="calendar-collection__status">
				<StatusItem title="Medicinintag" data={selectedData?.meds} />
				<StatusItem title="Humör" data={selectedData?.mood} />
				<StatusItem title="Positivism" data={selectedData?.spirit} />
				<StatusItem title="Vikt" data={selectedData?.weight} />
			</dl>

			{loading ? (
				<CalendarLoader />
			) : (
				<>
					<Calendar dates={dates} renderDate={renderDate} />

					<section className={classes} ref={bubbleRef}>
						<CalendarHeader
							title={swedishDateFormat(selectedData.date)}
							cta={() => setOpenBubble(false)}
						/>

						{selectedData.items.length > 0 ? (
							<>
								{selectedData.meds ?
									<section aria-labelledby="meds" data-presentation-type="meds">
										<h3 id="meds">Medicinintag</h3>

										<ul>
											{selectedData.meds.map(
												({ id, name, quantity }) => (
													<li key={id}>
														{`${name} ${quantity >= 1 ? `(${quantity})` : ""}`}
													</li>
												)
											)}
										</ul>
									</section>
									: null
								}

								{selectedData.spirit ?
									<section aria-labelledby="positive" data-presentation-type="spirit">
										<h3 id="positive">
											Positivism {totalPositiveItems > 0 && <span>({totalPositiveItems})</span>}
										</h3>

										<ul>
											{selectedData.spirit.map(
												({ id, event }) => {
													if (event) {
														return splitSpiritEvents(event).map(
															(item) => (
																<li key={item}>{item}</li>
															)
														);
													} else {
														return <li key={id}>Dålig dag</li>;
													}
												})}
										</ul>
									</section>
									: null
								}

								{selectedData.mood ?
									<section aria-labelledby="mood" data-presentation-type="mood">
										<h3 id="mood">Humör</h3>

										{selectedData.mood.map(
											({ id, mood }) => (
												<div key={id} className="calendar-collection__mood">
													<span className="mood__label">
														{moodLabel(mood)}
													</span>
													<div className="mood__icon">
														{moodMap(mood)}
													</div>
												</div>
											)
										)}
									</section>
									: null
								}

								{selectedData.weight ?
									<section aria-labelledby="weight" data-presentation-type="weight">
										<h3 id="weight">Kroppsvikt</h3>

										{selectedData.weight.map(
											({ id, amount }) => (
												<p key={id}>
													{amount} kg
												</p>
											)
										)}
									</section>
									: null
								}
							</>
						) : (
							<DisplayNoDataMessage classes="calendar-presentation__msg" />
						)}
					</section>
				</>
			)}
		</section>
	);
};

export default All;
