import { formatDate } from "../../utils";
import Field from "../FormTemplate/Field";
import RadioOption from "../FormTemplate/Radio";
import FormCreate from "../FormTemplate/FormCreate";
import { Button } from "../Template/Button";

const table = "survey/keds";

const Answers = ({ a0, a1, a2, a3, a4, a5, a6, num }) => (
	<>
		<RadioOption number={num} cat="q" option="none" label={a0} value="0" />
		<RadioOption number={num} cat="q" option="very-slightly" label={a1} value="1" />
		<RadioOption number={num} cat="q" option="slightly" label={a2} value="2" />
		<RadioOption number={num} cat="q" option="moderately" label={a3} value="3" />
		<RadioOption number={num} cat="q" option="quite-a-bit" label={a4} value="4" />
		<RadioOption number={num} cat="q" option="very-much" label={a5} value="5" />
		<RadioOption number={num} cat="q" option="extremely" label={a6} value="6" />
	</>
);

const Section = ({ q, children, cat }) => (
	<section className="field field--survey">
		<p className="field--survey__tag">{cat}</p>
		<h3>{q}</h3>
		{children}
	</section>
);

const KEDSFields = ({
	keds = {
		date: formatDate(new Date()),
	},
	title = "KEDS",
}) => (
	<fieldset className="form form--survey">
		<legend>{title}</legend>
		<div className="form__layout">
			<Field
				id="suvey-date"
				name="date"
				type="date"
				label="Datum"
				value={keds.date}
			/>

			<section className="form__note">
				<h3>Anteckning</h3>
				<p>Svara för de senaste 14 dagarna.</p>
			</section>

			<p className="form__instructions">
				Fyll i enkäten utifrån hur det har varit sedan föregående gång.
				Hur ofta har du besvärats av följande problem?
			</p>

			<Section
				q="Här ber vi dig ta ställning till din förmåga att hålla tankarna samlade och koncentrera dig. Tänk igenom hur du
fungerar vid olika sysslor som kräver olika grad av koncentrationsförmåga, t ex läsning av komplicerad text, lätt
tidningstext och TV-tittande."
				cat="Koncentrationsförmåga"
			>
				<Answers
					num="1"
					a0="Jag har inte svårt att koncentrera mig utan läser, tittar på TV och för samtal som vanligt."
					a1=""
					a2="Jag har ibland svårt att hålla tankarna samlade på sådant som normalt skulle fånga min"
					a3=""
					a4="Jag har ofta svårt att koncentrera mig."
					a5=""
					a6="Jag kan överhuvudtaget inte koncentrera mig på någonting."
				/>
			</Section>

			<Section
				q="Här ber vi dig beskriva din förmåga att komma ihåg saker. Tänk efter om du har svårt att komma ihåg namn,
datum eller vardagliga ärenden"
				cat="Minne">
				<Answers
					num="2"
					a0="Jag kommer ihåg namn, datum och ärenden jag ska göra."
					a1=""
					a2="Det händer att jag glömmer bort sådant som inte är så viktigt men om jag skärper mig minns jag
för det mesta."
					a3=""
					a4="Jag glömmer ofta bort möten eller namnen på personer som jag känner mycket väl."
					a5=""
					a6="Jag glömmer dagligen bort betydelsefulla saker eller saker som jag skulle gjort."
				/>
			</Section>

			<Section q="Frågan gäller hur du har det med din fysiska ork. Känner du dig t.ex. mer fysiskt trött än vanligt efter vardagliga
sysslor eller någon form av kroppsansträngning?" cat="Kroppslig uttröttbarhet">
				<Answers
					num="3"
					a0="Jag känner mig som vanligt och utför fysiska aktiviteter som ingår i vardagen eller tränar som jag
brukar."
					a1=""
					a2="Jag känner att fysiska ansträngningar är mer tröttande än normalt men rör mig ändå som vanligt
i det avseendet."
					a3=""
					a4="Jag har svårt att orka med kroppsansträngning. Det fungerar så länge jag rör mig i normal takt men
jag klarar inte att öka takten utan att bli darrig och andfådd."
					a5=""
					a6="Jag känner mig mycket svag och orkar inte ens att röra mig kortare sträckor."
				/>
			</Section>

			<Section q="Här vill vi att du tänker efter hur din uthållighet är och om du blir lättare psykiskt trött än vanligt i olika
vardagliga situationer." cat="Uthållighet">
				<Answers
					num="4"
					a0="Jag har lika mycket energi som vanligt. Jag har inga särskilda svårigheter att genomföra mina
vardagliga sysslor."
					a1=""
					a2="Jag klarar av att genomföra vardagliga sysslor men det går åt mer energi och jag blir fortare trött
än vanligt. Jag behöver ta pauser oftare än vanligt."
					a3=""
					a4="Jag blir onormalt trött av att försöka utföra mina vardagssysslor och umgänge med andra människor
tröttar ut mig."
					a5=""
					a6="Jag orkar inte göra någonting."
				/>
			</Section>

			<Section
				q="Här ber vi dig beskriva hur väl och hur snabbt du återhämtar dig psykiskt och fysiskt när du har blivit uttröttad."
				cat="Återhämtning"
			>
				<Answers
					num="5"
					a0="Jag behöver inte vila under dagen."
					a1=""
					a2="Jag blir trött under dagen men det räcker med en liten paus för att jag ska återhämta mig."
					a3=""
					a4="Jag blir trött under dagen och behöver långa pauser för att bli piggare."
					a5=""
					a6="Det spelar ingen roll hur mycket jag vilar, det är som om jag inte kan ladda om mina batterier."
				/>
			</Section>

			<Section q="Här ber vi dig beskriva hur du sover. Tänk efter hur god sömnen varit och/eller om du känt dig utsövd under de
senaste två veckorna. Bedömningen skall avse hur du faktiskt sovit, oavsett om du tagit sömnmedel eller e" cat="Sömn">
				<Answers
					num="6"
					a0="Jag sover gott och tillräckligt länge för mina behov och känner mig för det mesta utvilad när jag
vaknar."
					a1=""
					a2="Ibland sover jag oroligare eller vaknar under natten och har svårt att somna om. Det händer att jag
inte känner mig utsövd efter en natts sömn."
					a3=""
					a4="Jag sover ofta oroligt eller vaknar under natten och har svårt att somna om. Det händer ofta att jag
inte känner mig utsövd efter en natts sömn.."
					a5=""
					a6="Jag sover oroligt eller vaknar varje natt och har svårigheter att somna om. Jag känner mig aldrig
utvilad eller utsövd när jag vaknar."
				/>
			</Section>

			<Section
				q="Frågan gäller om du tycker att något eller några av dina sinnen blivit mer känsliga för intryck. T.ex. ljud, ljus, dofter eller
beröring"
				cat="Överkänslighet för sinnesintryck"
			>
				<Answers
					num="7"
					a0="Jag tycker inte att mina sinnen är känsligare än vanligt"
					a1=""
					a2="Det händer att ljud, ljus eller andra sinnesintryck känns obehagliga."
					a3=""
					a4="Jag upplever ofta ljud, ljus eller andra sinnesintryck som störande eller obehagliga."
					a5=""
					a6="Ljud, ljus eller andra sinnesintryck stör mig så mycket att jag drar mig undan för att mina sinnen ska
få vila."
				/>
			</Section>

			<Section
				q="Här ber vi dig ta ställning till hur du reagerar på krav som du upplever ställs på dig i vardagen. Kraven kan komma från
omgivningen eller dig själv."
				cat="Upplevelsen av krav"
			>
				<Answers
					num="8"
					a0="Jag gör det jag ska eller vill göra utan att uppleva det som särskilt krävande eller besvärligt."
					a1=""
					a2="Vardagliga situationer som jag tidigare hanterat utan särskilda problem kan ibland kännas krävande
och orsaka obehag eller få mig att bli lättare stressad än vanligt."
					a3=""
					a4="Situationer som jag tidigare hanterat utan problem känns nu ofta krävande och orsakar ett starkt
obehag eller en stark stress."
					a5=""
					a6="Det mesta känns krävande och jag klarar inte av att hantera det överhuvudtaget."
				/>
			</Section>

			<Section q="Frågan gäller hur lättirriterad eller arg du känner dig inombords oavsett om du visat något utåt eller ej. Tänk särskilt efter hur
lättväckt din irritation varit (”kort stubin”), i förhållande till vad som utlöst den, och på hur ofta och hur intensivt du känt dig
arg eller irriterad. Om du överhuvudtaget inte kan känna några sådana känslor, skall du sätta din markering vid 0." cat="Irritation och ilska">
				<Answers
					num="9"
					a0="Jag känner mig inte särskilt lättirriterad."
					a1=""
					a2="Jag känner mig mer otålig eller lättirriterad än vanligt men det går också snabbt över."
					a3=""
					a4="Jag blir lättare arg eller provocerad än vanligt. Ibland förlorar jag fattningen på ett sätt som inte är
normalt för mig."
					a5=""
					a6="Jag känner mig ofta alldeles rasande invärtes och måste anstränga mig till det yttersta för att
behärska mig."
				/>
			</Section>
		</div>
		<div className="btn-container">
			<Button label="Spara" modifier="default" />
		</div>
	</fieldset>
);

export const AddKEDS = () => {
	return (
		<FormCreate endpoint={table} goTo="/surveys">
			<KEDSFields />
		</FormCreate>
	);
};
