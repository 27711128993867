import { useState, useMemo, Fragment, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { formatDate, swedishDateFormat, isDateEqual } from "../../utils";
import useAPI from "../../hooks/useAPI";
import useOutsideClick from "../../hooks/useOutsideClick";
import Calendar from "../Calendar";
import CalendarCell from "../Calendar/CalendarCell";
import CalendarHeader from "../Calendar/CalendarHeader";
import DisplayNoDataMessage from "../Template/DisplayNoDataMessage";
import { CalendarLoader } from "../Loader";
import DataBubble from "./DataBubble";
import Delete from '../FormTemplate/Delete';
import { goToLogs, PATH_MEDS } from '../../endpointConfig';

const MedicineCalendar = () => {
	const [selectedDate, setSelectedDate] = useState(new Date());
	const [openBubble, setOpenBubble] = useState(false);
	const { value: logg, loading } = useAPI("medicamento-logg");
	const dates = logg.map((x) => new Date(x.date));
	const bubbleRef = useRef(null);
	const { pathname } = useLocation();
	// prettier-ignore
	const classes = `calendar-presentation ${openBubble ? "is-open" : ""}`.trim();

	useEffect(() => {
		setOpenBubble(false);
	}, [pathname]);

	useOutsideClick(bubbleRef, () => {
		if (openBubble) setOpenBubble(false);
	});

	const selectedLogg = useMemo(() => {
		const formatted = formatDate(selectedDate);

		return {
			items: logg.filter((x) => x.date === formatted),
			date: formatted,
		};
	}, [selectedDate, logg]);

	const renderDate = (date, { month }) => {
		const count = dates.filter((x) => isDateEqual(x, date)).length;
		const active = count > 0;

		return (
			<CalendarCell
				date={date}
				month={month}
				selectedDate={selectedDate}
				onClick={() => {
					setSelectedDate(date);
					requestAnimationFrame(() => {
						setOpenBubble(true);
					});
				}}
				active={active}
			>
				{active ? <span>{count}</span> : null}
			</CalendarCell>
		);
	};

	return (
		<section className="section section--calendar">
			<h1 className="section__title">Medicin</h1>
			{loading ? (
				<CalendarLoader />
			) : (
				<>
					<Calendar dates={dates} renderDate={renderDate} />
					<section className={classes} ref={bubbleRef}>
						<CalendarHeader
							title={swedishDateFormat(selectedLogg.date)}
							cta={() => setOpenBubble(false)}
						/>
						{selectedLogg.items.length > 0 ? (
							<dl className="calendar-presentation__logg calendar-presentation__logg--meds">
								{selectedLogg.items.map(
									({ id, name, quantity }) => (
										<Fragment key={id}>
											<DataBubble
												label="Läkemedel"
												content={`${name} ${quantity >= 1 ? `(${quantity})` : ""}`}
											/>
											<dd>
												<Delete
													id={id}
													label={`${swedishDateFormat(selectedLogg.date)}: ${name} ${quantity >= 1 ? `(${quantity})` : ""}`}
													dbEndpoint="medicamento-logg"
													pathAfterDeletion={`${goToLogs}/${PATH_MEDS}`}
												/>
											</dd>
										</Fragment>
									)
								)}
							</dl>
						) : (
							<DisplayNoDataMessage classes="calendar-presentation__msg" />
						)}
					</section>
				</>
			)}
		</section>
	);
};

export default MedicineCalendar;
