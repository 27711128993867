import { isDateEqual } from "../../utils";

export default function CalendarCell({
	date,
	active,
	month,
	selectedDate,
	onClick,
	children,
	className,
}) {
	const day = date.getDate();
	const isMonth = date.getMonth() === month;
	const isSelected = isDateEqual(selectedDate, date);
	const isToday = isDateEqual(date, new Date());

	const classes = [
		"calendar__cell",
		!isMonth && "is-dimmed",
		isSelected && "is-selected",
		isToday && "is-today",
		isSelected && isToday && "is-highlighted",
		className,
	]
		.filter(Boolean)
		.join(" ");

	return active ? (
		<button
			type="button"
			onClick={onClick}
			className={classes}
			disabled={!isMonth}
		>
			<span className="calendar__cell-day">{day}</span>
			<>{children}</>
		</button>
	) : (
		<span className={classes}>
			<span className="calendar__cell-day">{day}</span>
			<>{children}</>
		</span>
	);
}
