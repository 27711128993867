import { useState } from "react";
import useAPI from "../../hooks/useAPI";
import Checkbox from "../FormTemplate/Checkbox";
import Delete from "../FormTemplate/Delete";
import { EditButton } from "../Template/Button";
import { Star } from "../Template/Star";
import Loader from "../Loader";
import Accordion from "../Accordion";
import { goToLogs, PATH_DRUG } from '../../endpointConfig';

const DrugTitle = ({ name, fav }) => (
	<>
		{name} {fav === "1" ? <Star /> : null}
	</>
);

const DrugList = () => {
	const { value: drugs, loading } = useAPI("medicamento");
	const [toggle, setToggle] = useState(false);

	return (
		<section className="section">
			<Checkbox
				id="active"
				name="active"
				label="Visa aktuella meds"
				onClick={() => setToggle(!toggle)}
			/>
			<h1 className="section__title">Katalog [mediciner]</h1>
			{loading ? (
				<Loader />
			) : (
				<>
					{drugs.map(
						({
							id,
							startdate,
							enddate,
							name,
							dosage,
							daily_dosage: dailyDosage,
							strength,
							unit,
							form,
							note,
							fav,
							active,
						}) =>
							(!toggle || (toggle && active === "1")) && (
								<Accordion
									key={id}
									name={<DrugTitle name={name} fav={fav} />}
									modifier="list"
								>
									<dl>
										<div>
											<dt>Medicin</dt>
											<dd>{name}</dd>
										</div>

										<div>
											<dt>Start</dt>
											<dd>
												<time dateTime={startdate}>
													{startdate}
												</time>
											</dd>
										</div>

										{enddate ? (
											<div>
												<dt>Slut</dt>
												<dd>
													<time dateTime={enddate}>
														{enddate}
													</time>
												</dd>
											</div>
										) : (
											""
										)}

										<div>
											<dt>Aktiv</dt>
											{active === "1" ? (
												<dd>Ja</dd>
											) : (
												<dd>Nej</dd>
											)}
										</div>

										<div>
											<dt>Dosering</dt>
											<dd>{dosage}</dd>
										</div>

										{dailyDosage ? (
											<div>
												<dt>Daglig dos</dt>
												<dd>{dailyDosage}</dd>
											</div>
										) : (
											""
										)}

										<div>
											<dt>Styrka</dt>
											<dd>
												{strength} {unit}
											</dd>
										</div>

										<div>
											<dt>Beredningsform</dt>
											<dd>{form}</dd>
										</div>

										{note ? (
											<div className="u-cell-span">
												<dt>Anteckning</dt>
												<dd className="u-dd-note">
													{note}
												</dd>
											</div>
										) : (
											""
										)}
									</dl>

									<div className="btn-container">
										<Delete
											id={id}
											label={name}
											dbEndpoint="medicamento"
											pathAfterDeletion={`${goToLogs}/${PATH_DRUG}`}
										/>
										<EditButton
											id={id}
											segment="medicamento"
										/>
									</div>
								</Accordion>
							)
					)}
				</>
			)}
		</section>
	);
};

export default DrugList;
