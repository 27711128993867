import useAPI from "../../hooks/useAPI";
import FormCreate from "../FormTemplate/FormCreate";
import Field from '../FormTemplate/Field';
import { Button, AbortButton } from "../Template/Button";
import { FormLoader } from "../Loader";
import { goToLogs, PATH_MOOD } from "../../endpointConfig";
import { formatDateWithTimestamp, moodConfig } from "../../utils";

const MoodFields = ({
	id,
	mood,
	title = "Sätt dagens genomsnittliga humör",
}) => (
	<fieldset className="form form--emoticon">
		<legend>{title}</legend>
		<Field
			type="datetime-local"
			id="mood-date"
			name="date"
			label="Datum"
			value={formatDateWithTimestamp(new Date())}
			required
		/>

		<ul className="form--emoticon__grid">
			{moodConfig.map(({ level, icon, label }) => (
				<li key={level}>
					<input
						type="radio"
						id={`mood-${level}`}
						name="mood"
						value={level}
						defaultChecked={mood === level}
					/>
					<label
						htmlFor={`mood-${level}`}
						className="form--emoticon__label"
					>
						{icon}
						<span className="form--emoticon__tagname">{label}</span>
					</label>
				</li>
			))}
		</ul>
		{id != null ? <input type="hidden" name="id" value={id} /> : null}
		<div className="btn-container">
			<AbortButton pathTo={`.${goToLogs}/${PATH_MOOD}`} label="Avbryt" />
			<Button label="Spara" modifier="default" />
		</div>
	</fieldset>
);

export const AddMood = () => {
	const { value, loading } = useAPI(`${PATH_MOOD}/today`);

	if (loading) {
		return <FormLoader />;
	}

	return (
		<FormCreate
			endpoint={PATH_MOOD}
			goTo={`${goToLogs}/${PATH_MOOD}`}
			modifier="mood"
		>
			<MoodFields mood={value?.mood} id={value?.id} />
		</FormCreate>
	);
};
