import { formatDate } from "../../utils";
import Field from "../FormTemplate/Field";
import RadioOption from "../FormTemplate/Radio";
import FormCreate from "../FormTemplate/FormCreate";
import { Button } from "../Template/Button";

const table = "survey/phq9";

const Answers = ({ number }) => (
	<>
		<RadioOption
			number={number}
			cat="q"
			option="none"
			label="Inte alls"
			value="0"
		/>
		<RadioOption
			number={number}
			cat="q"
			option="slightly"
			label="Flera dagar"
			value="1"
		/>
		<RadioOption
			number={number}
			cat="q"
			option="moderate"
			label="Mer än hälften av dagarna"
			value="2"
		/>
		<RadioOption
			number={number}
			cat="q"
			option="much"
			label="Nästan varje dag"
			value="3"
		/>
	</>
);

const Section = ({ q, num }) => (
	<section className="field field--survey">
		<h3>{q}</h3>
		<Answers number={num} />
	</section>
);

const PHQ9Fields = ({
	phq9 = {
		date: formatDate(new Date()),
	},
	title = "PHQ-9",
}) => (
	<fieldset className="form form--survey">
		<legend>{title}</legend>
		<div className="form__layout">
			<Field
				id="suvey-date"
				name="date"
				type="date"
				label="Datum"
				value={phq9.date}
			/>

			<p className="form__instructions">
				Fyll i enkäten utifrån hur det har varit sedan föregående gång.
				Hur mycket har du besvärats av något av följande problem?
			</p>

			<p className="form__instructions">
				Fyll i enkäten utifrån hur det har varit sedan föregående gång.
				<br />
				Under de senaste 2 veckorna, hur ofta har du besvärats av något av följande problem.
			</p>

			<Section q="Lite intresse eller glädje av att göra saker" num="1" />
			<Section
				q="Känt dig nedstämd, deprimerad eller upplevt känsla av hopplöshet"
				num="2"
			/>
			<Section
				q="Svårigheter att somna eller få en sammanhängande sömn, eller sovit för mycket"
				num="3"
			/>
			<Section q="Känt dig trött eller haft för lite energi" num="4" />
			<Section q="Dålig aptit eller ätit för mycket" num="5" />
			<Section
				q="Tycker illa om dig själv &ndash; eller att du känt dig misslyckad eller att du svikit dig själv eller din familj"
				num="6"
			/>
			<Section
				q="Svårigheter att koncentrera dig på saker, till exempel att läsa tidningen eller att titta på TV"
				num="7"
			/>
			<Section
				q="Att du rört dig eller talat så långsamt att andra människor märkt det? Eller motsatsen &ndash; att du varit så nervös eller rastlös att du rört dig mer än vanligt"
				num="8"
			/>
			<Section
				q="Tankar att det skulle vara bättre om du var död eller att du skulle skada dig på något sätt"
				num="9"
			/>

			<section className="field field--survey">
				<h3>Om du har kryssat för att du haft något av dessa problem, hur stora har dessa problem förorsakat dig på arbetet, eller för att ta hand om sysslor hemma, eller i kontakten med andra människor?</h3>
				<RadioOption
					number="10"
					cat="q"
					option="none"
					label="Inga svårigheter"
					value="0"
				/>
				<RadioOption
					number="10"
					cat="q"
					option="slightly"
					label="Vissa svårigheter"
					value="1"
				/>
				<RadioOption
					number="10"
					cat="q"
					option="moderate"
					label="Stora svårigheter"
					value="2"
				/>
				<RadioOption
					number="10"
					cat="q"
					option="extreme"
					label="Extrema svårigheter"
					value="3"
				/>
			</section>
		</div>
		<div className="btn-container">
			<Button label="Spara" modifier="default" />
		</div>
	</fieldset>
);

export const AddPHQ9 = () => {
	return (
		<FormCreate endpoint={table} goTo="/surveys">
			<PHQ9Fields />
		</FormCreate>
	);
};
