import { useState, useMemo, Fragment, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { isDateEqual, splitSpiritEvents, swedishDateFormat } from "../../utils";
import useAPI from "../../hooks/useAPI";
import useOutsideClick from "../../hooks/useOutsideClick";
import Calendar from "../Calendar";
import CalendarCell from "../Calendar/CalendarCell";
import CalendarHeader from "../Calendar/CalendarHeader";
import DisplayNoDataMessage from "../Template/DisplayNoDataMessage";
import Delete from "../FormTemplate/Delete";
import { EditButton } from "../Template/Button";
import { CalendarLoader } from "../Loader";
import DataBubble from "./DataBubble";
import { goToLogs, PATH_POS } from '../../endpointConfig';

const EventsCalendar = () => {
	const [selectedDate, setSelectedDate] = useState(new Date());
	const [openBubble, setOpenBubble] = useState(false);
	const { value: logg, loading } = useAPI("positivismum");
	const bubbleRef = useRef(null);
	const { pathname } = useLocation();
	// prettier-ignore
	const classes = `calendar-presentation ${openBubble ? "is-open" : ""}`.trim();

	useEffect(() => {
		setOpenBubble(false);
	}, [pathname]);

	useOutsideClick(bubbleRef, () => {
		if (openBubble) setOpenBubble(false);
	});

	const dates = useMemo(() => {
		return logg.map((item) => new Date(item.date));
	}, [logg]);

	const selectedEvent = useMemo(() => {
		return logg.find((x) => isDateEqual(x.date, selectedDate));
	}, [selectedDate, logg]);

	const renderDate = (date, { month }) => {
		const item = logg.find((x) => isDateEqual(x.date, date));
		const active = !!item;
		const count = splitSpiritEvents(item?.event).length;

		return (
			<CalendarCell
				date={date}
				month={month}
				selectedDate={selectedDate}
				onClick={() => {
					setSelectedDate(date);
					requestAnimationFrame(() => {
						setOpenBubble(true);
					});
				}}
				active={active}
			>
				{active && count > 0 ? (
					<span className="calendar__cell-count">{count}</span>
				) : active ? (
					<div className="calendar__cell-dot"></div>
				) : null}
			</CalendarCell>
		);
	};

	return (
		<section className="section section--calendar">
			<h1 className="section__title">Positiva händelser</h1>
			{loading ? (
				<CalendarLoader />
			) : (
				<>
					<Calendar dates={dates} renderDate={renderDate} />
					<section className={classes} ref={bubbleRef}>
						<CalendarHeader
							title={swedishDateFormat(selectedDate)}
							cta={() => setOpenBubble(false)}
						/>
						{selectedEvent ? (
							<Fragment key={selectedEvent.id}>
								{selectedEvent.event != null ? (
									<dl className="calendar-presentation__logg">
										{splitSpiritEvents(selectedEvent.event).map(
											(item) => (
												<DataBubble
													key={item}
													label="-"
													content={item}
												/>
											)
										)}
									</dl>
								) : (
									<dl className="calendar-presentation__logg">
										<DataBubble
											label="-"
											content="Dålig dag"
										/>
									</dl>
								)}
								<div className="btn-container">
									<Delete
										id={selectedEvent.id}
										label="eventet"
										dbEndpoint="positivismum"
										pathAfterDeletion={`${goToLogs}/${PATH_POS}`}
									/>
									<EditButton
										id={selectedEvent.id}
										segment="positivismum"
									/>
								</div>
							</Fragment>
						) : (
							<DisplayNoDataMessage classes="calendar-presentation__msg" />
						)}
					</section>
				</>
			)}
		</section>
	);
};

export default EventsCalendar;
